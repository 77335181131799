import React, { useState, useMemo } from 'react';
import {
  Typography,
  Popper,
  TextField,
  createMuiTheme,
  MuiThemeProvider,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Card } from 'reactstrap';
import PresetsPreview from './PresetsPreview';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { deletePreset, savePresets, updatePreset } from '../../../Api/userAPI';
import { TOAST_MESSAGE } from '../../../Common/constant_text';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { loadSearchPresets } from '../../../Redux/Actions/searchPresets';

const defaultPresetsState = { label: '', displayOrder: '', content: {} };

function CreatePresetModal({ id, anchorEl, filterData, onClose, applyPreset }) {
  const [createFormData, setCreateFormData] = useState(defaultPresetsState);
  const [editFormData, setEditFormData] = useState(defaultPresetsState);
  const [hasShowPreviewModal, setHasShowPreviewModal] = useState(false);
  const [selectedPresets, setSelectedPresets] = useState(null);
  const [isEditPreset, setIsEditPreset] = useState(false);
  const [isLoadPresetBtnDisabled, setLoadPresetBtnDisabled] = useState(true);
  const [isPreviewSubmitBtnDisabled, setIsPreviewSubmitBtnDisabled] =
    useState(false);

  const { loading: isLoadingPresets, data: fetchedPresets } = useSelector(
    (state) => state.searchPresets,
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    setCreateFormData((prev) => {
      prev.content = { ...filterData };
      return { ...prev };
    });
    setEditFormData((prev) => {
      prev.content = { ...filterData };
      return { ...prev };
    });
    setIsPreviewSubmitBtnDisabled((prev) => {
      if (prev) return false;
    });
  }, [filterData]);

  React.useEffect(() => {
    dispatch(loadSearchPresets());
  }, [dispatch, anchorEl]);

  React.useEffect(() => {
    const presetItem = fetchedPresets.find(
      (item) => item.id === selectedPresets,
    );
    if (!presetItem) {
      setEditFormData({ ...defaultPresetsState });
      setLoadPresetBtnDisabled(true);
      return;
    }
    setEditFormData((prev) => {
      if (prev.id === presetItem.id) return prev;
      const result = _.cloneDeep(presetItem);
      return { ...result, content: prev.content };
    });
  }, [selectedPresets, fetchedPresets]);

  const handleCreateFormDataChange = (e) => {
    createFormData[e.target.name] = e.target.value;
    setCreateFormData({ ...createFormData });
  };

  const handleEditFormDataChange = (e) => {
    editFormData[e.target.name] = e.target.value;
    setEditFormData({ ...editFormData });
  };

  const openPreviewModal = ({ isEditMode }) => {
    return function curryFn(e) {
      setIsEditPreset(isEditMode);
      setHasShowPreviewModal(true);
    };
  };
  const closePreviewModal = () => {
    setHasShowPreviewModal(false);
  };

  const isSubmitButtonNewPresetDisabled = useMemo(() => {
    return !(createFormData.label && createFormData.displayOrder);
  }, [createFormData]);

  const handleSubmitNewPreset = async () => {
    try {
      const values = {
        ...createFormData,
        displayOrder: +createFormData.displayOrder,
      };
      setIsPreviewSubmitBtnDisabled(true);
      const result = await savePresets(values);
      if (result) {
        toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        dispatch(loadSearchPresets());
        setCreateFormData(defaultPresetsState);
        closePreviewModal();
      }
    } catch (err) {
      toast.error(err.response?.data?.message);
      setIsPreviewSubmitBtnDisabled(false);
    }
  };

  const handleChangeSelectPresets = (e) => {
    setSelectedPresets(e.target.value);
    setLoadPresetBtnDisabled(false);
    setIsEditPreset(true);
  };

  const handleLoadPreset = () => {
    const { content: filter } = fetchedPresets.find(
      (e) => e.id === selectedPresets,
    );
    console.log(filter);
    applyPreset(filter);
    setLoadPresetBtnDisabled(true);
  };

  const handleSubmitEditPreset = async () => {
    try {
      const { id, ...values } = editFormData;
      setIsPreviewSubmitBtnDisabled(true);
      const result = await updatePreset(selectedPresets, values);
      if (result) {
        toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
        dispatch(loadSearchPresets());
        setIsEditPreset(false);
        closePreviewModal();
      }
    } catch (err) {
      toast.error(err.response?.data?.message);
      setIsPreviewSubmitBtnDisabled(false);
    }
  };

  const handleSubmitDeletePreset = async () => {
    try {
      const result = await deletePreset(selectedPresets);
      if (result) toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
      setSelectedPresets(null);
      setIsEditPreset(false);
      dispatch(loadSearchPresets());
    } catch (err) {
      toast.error(err.response?.data?.message);
    }
  };

  return (
    <>
      <PresetsPreview
        open={hasShowPreviewModal}
        onClose={closePreviewModal}
        handleSubmit={
          isEditPreset ? handleSubmitEditPreset : handleSubmitNewPreset
        }
        formData={isEditPreset ? editFormData : createFormData}
        isSubmitBtnDisabled={isPreviewSubmitBtnDisabled}
      />
      <MuiThemeProvider theme={theme}>
        <Popper
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          className={'d-modal-wrapper'}
          disablePortal
          placement="top-end"
          modifiers={{
            flip: { enabled: false },
          }}
          style={PopperStyle}
        >
          <div
            style={{
              padding: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">登録</Typography>
            <div>
              <IconButton onClick={onClose} color="primary">
                <Close />
              </IconButton>
            </div>
          </div>
          <Card body>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <Typography style={{ color: '#4F4F4F', fontWeight: 500 }}>
                メモリーの保存
              </Typography>
              <TextField
                label="説明テキスト"
                type="text"
                name="label"
                value={createFormData.label}
                onChange={handleCreateFormDataChange}
                className="w-100"
              />
              <TextField
                label="Display Order"
                type="number"
                name="displayOrder"
                value={createFormData.displayOrder}
                onChange={handleCreateFormDataChange}
                className="w-100"
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '5px',
                }}
              >
                <Button
                  onClick={openPreviewModal({ isEditMode: false })}
                  color="primary"
                  className="btn-custom btn-blue"
                  disabled={isSubmitButtonNewPresetDisabled}
                >
                  保存
                </Button>
              </div>
            </div>
            <div
              style={{
                marginTop: '25px',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
              }}
            >
              <Typography style={{ color: '#4F4F4F', fontWeight: 500 }}>
                メモリーの適用
              </Typography>
              <Select
                value={selectedPresets}
                disabled={isLoadingPresets}
                onChange={handleChangeSelectPresets}
                fullWidth
                MenuProps={MenuProps}
              >
                {fetchedPresets.map((e) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.label}
                  </MenuItem>
                ))}
              </Select>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '5px',
                }}
              >
                <Button
                  disabled={isLoadPresetBtnDisabled}
                  onClick={handleLoadPreset}
                  color="primary"
                  className="btn-custom btn-blue"
                >
                  メモリーを適用する
                </Button>
              </div>
            </div>
            <div
              style={{
                marginTop: '25px',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '15px',
              }}
            >
              <Typography style={{ color: '#4F4F4F', fontWeight: 500 }}>
                メモリーの編集
              </Typography>
              <React.Fragment>
                <TextField
                  disabled={!selectedPresets || isLoadingPresets}
                  label="説明テキスト"
                  type="text"
                  name="label"
                  value={editFormData.label}
                  onChange={handleEditFormDataChange}
                  className="w-100"
                />
                <TextField
                  disabled={!selectedPresets || isLoadingPresets}
                  label="Display Order"
                  type="number"
                  name="displayOrder"
                  value={editFormData.displayOrder}
                  onChange={handleEditFormDataChange}
                  className="w-100"
                />
              </React.Fragment>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '15px',
                }}
              >
                <Button
                  disabled={!selectedPresets}
                  onClick={openPreviewModal({ isEditMode: true })}
                  color="primary"
                  className="btn-custom btn-blue"
                >
                  メモリーの更新
                </Button>
                <Button
                  disabled={!selectedPresets}
                  onClick={handleSubmitDeletePreset}
                  color="primary"
                  className="btn-red"
                >
                  削除
                </Button>
              </div>
            </div>
          </Card>
        </Popper>
      </MuiThemeProvider>
    </>
  );
}

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        '&$disabled': {
          opacity: '0.4',
        },
      },
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: '#4796ef',
        },
      },
      '&$checked + $track': {
        backgroundColor: '#4796ef',
      },
      track: {
        '$checked$checked + &': {
          opacity: 0.7,
          backgroundColor: '#4796ef',
        },
      },
    },
  },
});

const PopperStyle = {
  margin: '60px 0 15px 0',
  padding: '10px',
  backgroundColor: '#efefef',
  borderRadius: '10px',
  minWidth: '500px',
  zIndex: '1000',
  boxShadow:
    'rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px',
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

export default CreatePresetModal;
