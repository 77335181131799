import { combineReducers } from 'redux';
import usersReducer from './usersReducer';
import userReducer from './userReducer';
import imagesReducer from './imagesReducer';
import campaignsReducer from './campaignsReducer';
import pointReducer from './pointReducer';
import resourceReducer from './resourceReducer';
import pointUserReducer from './pointUserReducer';
import likeUserReducer from './likeUserReducer';
import chatUserReducer from './chatUserReducer';
import likeUserByMeReducer from './likeUserByMeReducer';
import chatMessageReducer from './chatMessageReducer';
import contactsReducer from './contactsReducers';
import reportsReducer from './reportsReducer';
import listRawUsersReducer from './listRawUsersReducer';
import listRawDatabaseReducer from './listRawDatabaseReducer';
import listContactsReducer from './listContactsReducer';
import listMessageReducer from './listMessageReducer';
import listCampaignReducer from './listCampaignReducer';
import listChatReducer from './listChatReducer';
import listPaymentReducer from './listPaymentReducer';
import operatorsReducer from './operatorsReducer';
import operatorReducer from './operatorReducer';
import detailOperatorReducer from './detailOperatorReducer';
import listConversationReducer from './listConversationReducer';
import pointSettingReducer from './pointSettingReducer';
import logSendSmsReducers from './logSendSmsReducers';
import nameRankReducer from './nameRankReducer';
import nameStateReducer from './nameStateReducer';
import environmentReducer from './environmentReducer';
import resourceAgentReducer from './resourceAgentReducer';
import twilioReducer from './twilioReducer';
import pointUsageReducer from './pointUsageReducer';
import envUIReducer from './envUIReducer';
import chatLogReducer from './chatLogReducer';
import logSendMailReducer from './logSendMailReducer';
import agentReducer from './agentReducer';
import agentsReducer from './agentsReducer';
import characterReducer from './characterReducer';
import likeLogReducer from './likeLogReducer';
import salaryLogReducer from './salaryLogReducer';
import envUICmsReducer from './envUICmsReducer';
import envUISidenavReducer from './envUISidenavReducer';
import favoriteLogReducer from './favoriteLogReducer';
import favoriteUserToMeReducer from './favoriteUserToMeReducer';
import footprintLogReducer from './footprintLogReducer';
import footprintUserToMeReducer from './footprintUserToMeReducer';
import pointGetLogReducer from './pointGetLogReducer';
import blogsReducer from './blogsReducer';
import pointRateReducer from './pointRateReducer';
import popupReducer from './popupReducer';
import adminReducer from './adminReducer';
import timerMailReducer from './timerMailReducer';
import timerNotiReducer from './timerNotiReducer';
import timerSmsReducer from './timerSmsReducer';
import logSendNotiReducer from './logSendNotiReducer';
import blockReducer from './blockReducer';
import nameStateAgentReducer from './nameStateAgentReducer';
import memberAdminReducer from './memberAdminReducer';
import helpCmsReducer from './helpCmsReducer';
import timerChatRoomReducer from './timerChatRoomReducer';
import envInfoReducer from './envInfoReducer';
import informationReducer from './informationReducer';
import informationsReducer from './informationsReducer';
import helpMailSmsTextReducer from './helpMailSmsTextReducer';
import jobsReducer from './jobsReducer';
import jobReducer from './jobReducer';
import jobMembersReducer from './jobMembersReducer';
import jobMemberReducer from './jobMemberReducer';
import favoriteJobLogReducer from './favoriteJobLogReducer';
import envTopNavWebReducer from './envTopNavWebReducer';
import jobEntryMembersReducer from './jobEntryMembersReducer';
import textWebPageReducer from './textWebPageReducer';
import resourceItemJobReducer from './resourceItemJobReducer';
import nameStateJobReducer from './nameStateJobReducer';
import paymentJobReducer from './paymentJobReducer';
import searchPresets from './searchPresetsReducer';
import scheduleMailReducer from './scheduleMailReducer';

const rootReducer = combineReducers({
  users: usersReducer,
  user: userReducer,
  images: imagesReducer,
  campaigns: campaignsReducer,
  point: pointReducer,
  twilio: twilioReducer,
  resource: resourceReducer,
  pointUser: pointUserReducer,
  likeUser: likeUserReducer,
  chatUser: chatUserReducer,
  likeUserByMe: likeUserByMeReducer,
  chatMessage: chatMessageReducer,
  contacts: contactsReducer,
  reports: reportsReducer,
  listRawUser: listRawUsersReducer,
  listRawDatabase: listRawDatabaseReducer,
  listContact: listContactsReducer,
  listMessage: listMessageReducer,
  listCampaign: listCampaignReducer,
  listChat: listChatReducer,
  listPayment: listPaymentReducer,
  operators: operatorsReducer,
  operator: operatorReducer,
  detailOperator: detailOperatorReducer,
  listConversation: listConversationReducer,
  pointSetting: pointSettingReducer,
  logSendSms: logSendSmsReducers,
  nameRank: nameRankReducer,
  nameState: nameStateReducer,
  environment: environmentReducer,
  resourceAgent: resourceAgentReducer,
  pointUsage: pointUsageReducer,
  envUI: envUIReducer,
  chatLog: chatLogReducer,
  logSendMail: logSendMailReducer,
  agent: agentReducer,
  agents: agentsReducer,
  chara: characterReducer,
  like: likeLogReducer,
  salary: salaryLogReducer,
  envUICms: envUICmsReducer,
  envUISidenav: envUISidenavReducer,
  favorite: favoriteLogReducer,
  favoriteUserToMe: favoriteUserToMeReducer,
  footprint: footprintLogReducer,
  footprintUserToMe: footprintUserToMeReducer,
  pointGetLog: pointGetLogReducer,
  blogs: blogsReducer,
  pointRate: pointRateReducer,
  popups: popupReducer,
  admin: adminReducer,
  timerMail: timerMailReducer,
  timerNoti: timerNotiReducer,
  timerSms: timerSmsReducer,
  logSendNoti: logSendNotiReducer,
  block: blockReducer,
  nameStateAgent: nameStateAgentReducer,
  memberAdmin: memberAdminReducer,
  helpCms: helpCmsReducer,
  timerChatRoom: timerChatRoomReducer,
  envInfo: envInfoReducer,
  information: informationReducer,
  informations: informationsReducer,
  helpMailSmsText: helpMailSmsTextReducer,
  jobs: jobsReducer,
  job: jobReducer,
  jobMembers: jobMembersReducer,
  jobMember: jobMemberReducer,
  favoriteJob: favoriteJobLogReducer,
  envTopNavWeb: envTopNavWebReducer,
  jobEntryMembers: jobEntryMembersReducer,
  textWebPage: textWebPageReducer,
  resourceItemJob: resourceItemJobReducer,
  nameStateJob: nameStateJobReducer,
  paymentJob: paymentJobReducer,
  searchPresets: searchPresets,
  scheduleMail: scheduleMailReducer,
});

export default rootReducer;
