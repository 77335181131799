import { all } from 'redux-saga/effects';
import usersSaga from './usersSaga';
import listRawUsersSaga from './listRawUsersSaga';
import listRawDatabaseSaga from './listRawDatabaseSaga';
import userSaga from './userSaga';
import imagesSaga from './imagesSaga';
import campaignsSaga from './campaignsSaga';
import pointSaga from './pointSaga';
import resourceSaga from './resourcesSaga';
import pointUserSaga from './pointUserSaga';
import chatUserSaga from './chatUserSaga';
import likeUserSaga from './likeUserSaga';
import likeUserByMeSaga from './likeUserByMeSaga';
import chatMessageSaga from './chatMessageSage';
import contactsSaga from './contactsSaga';
import reportsSaga from './reportsSaga';
import listContactsSaga from './listContactsSaga';
import listMessageSaga from './listMessageSaga';
import listCampaignSaga from './listCampaignSaga';
import listChatSaga from './listChatSaga';
import listPaymentSaga from './listPaymentSaga';
import operatorsSaga from './operatorsSaga';
import operatorSaga from './operatorSaga';
import listConversationSaga from './listConversationSaga';
import pointSettingSaga from './pointSettingSaga';
import logSendSmsSaga from './logSendSmsSaga';
import nameStateSaga from './nameStateSaga';
import nameRankSaga from './nameRankSaga';
import environmentSaga from './environmentSaga';
import resourcesAgentSaga from './resourcesAgentSaga';
import twilioSaga from './twilioSaga';
import pointUsageSaga from './pointUsageSaga';
import envUISaga from './envUISaga';
import chatLogSaga from './chatLogSaga';
import logSendMailSaga from './logSendMailSaga';
import agentSaga from './agentSaga';
import agentsSaga from './agentsSaga';
import characterSaga from './characterSaga';
import likeLogSaga from './likeLogSaga';
import salaryLogSaga from './salaryLogSaga';
import envUICmsSaga from './envUICmsSaga';
import envUISidenavSaga from './envUISidenavSaga';
import favoriteLogSaga from './favoriteLogSaga';
import footprintLogSaga from './footprintLogSaga';
import pointGetLogSaga from './pointGetLogSaga';
import favoriteUserToMeSaga from './favoriteUserToMeSaga';
import footprintUserToMeSaga from './footprintUserToMeSaga';
import blogsSaga from './blogsSaga';
import pointRateSaga from './pointRateSaga';
import popupsSaga from './popupsSaga';
import adminSaga from './adminSaga';
import timerMailSaga from './timerMailSaga';
import timerNotiSaga from './timerNotiSaga';
import timerSmsSaga from './timerSmsSaga';
import logSendNotiSaga from './logSendNotiSaga';
import blockSaga from './blockSaga';
import nameStateAgentSaga from './nameStateAgentSaga';
import memberAdminSaga from './memberAdminSaga';
import helpCmsSaga from './helpCmsSaga';
import timerChatRoomSaga from './timerChatRoomSaga';
import envInfoSaga from './envInfoSaga';
import informationsSaga from './informationsSaga';
import informationSaga from './informationSaga';
import helpMailSmsTextSaga from './helpMailSmsTextSaga';
import jobsSaga from './jobsSaga';
import jobSaga from './jobSaga';
import jobMembersSaga from './jobMembersSaga';
import jobMemberSaga from './jobMemberSaga';
import favoriteJobLogSaga from './favoriteJobLogSaga';
import envTopNavWebSaga from './envTopNavWebSaga';
import jobEntryMembersSaga from './jobEntryMembersSaga';
import textWebPageSaga from './textWebPageSaga';
import resourcesItemJobSaga from './resourcesItemJobSaga';
import nameStateJobSaga from './nameStateJobSaga';
import paymentJobSaga from './paymentJobSaga';
import searchPresetsSaga from './searchPresetsSaga';
import scheduleMailSaga from './scheduleMailSaga';

export default function* rootSaga() {
  yield all([
    listRawDatabaseSaga(),
    listRawUsersSaga(),
    listContactsSaga(),
    usersSaga(),
    userSaga(),
    imagesSaga(),
    campaignsSaga(),
    pointSaga(),
    resourceSaga(),
    pointUserSaga(),
    chatUserSaga(),
    likeUserSaga(),
    likeUserByMeSaga(),
    chatMessageSaga(),
    contactsSaga(),
    reportsSaga(),
    listMessageSaga(),
    listCampaignSaga(),
    listChatSaga(),
    listPaymentSaga(),
    operatorsSaga(),
    operatorSaga(),
    listConversationSaga(),
    pointSettingSaga(),
    logSendSmsSaga(),
    nameRankSaga(),
    nameStateSaga(),
    environmentSaga(),
    resourcesAgentSaga(),
    pointUserSaga(),
    twilioSaga(),
    pointUsageSaga(),
    envUISaga(),
    chatLogSaga(),
    logSendMailSaga(),
    agentSaga(),
    agentsSaga(),
    characterSaga(),
    likeLogSaga(),
    salaryLogSaga(),
    envUICmsSaga(),
    envUISidenavSaga(),
    favoriteLogSaga(),
    footprintLogSaga(),
    pointGetLogSaga(),
    favoriteUserToMeSaga(),
    footprintUserToMeSaga(),
    blogsSaga(),
    blockSaga(),
    pointRateSaga(),
    popupsSaga(),
    adminSaga(),
    timerMailSaga(),
    timerNotiSaga(),
    logSendNotiSaga(),
    timerSmsSaga(),
    nameStateAgentSaga(),
    memberAdminSaga(),
    helpCmsSaga(),
    timerChatRoomSaga(),
    envInfoSaga(),
    informationsSaga(),
    informationSaga(),
    helpMailSmsTextSaga(),
    jobsSaga(),
    jobSaga(),
    jobMembersSaga(),
    jobMemberSaga(),
    favoriteJobLogSaga(),
    envTopNavWebSaga(),
    jobEntryMembersSaga(),
    textWebPageSaga(),
    resourcesItemJobSaga(),
    nameStateJobSaga(),
    paymentJobSaga(),
    searchPresetsSaga(),
    scheduleMailSaga(),
  ]);
}
