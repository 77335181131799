import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadResource } from '../Redux/Actions/resource';
import { loadNameRank } from '../Redux/Actions/nameRank';
import { loadState } from '../Redux/Actions/nameState';
import { loadEnvUICms } from '../Redux/Actions/envUICms';
import { loadEnvUISidenav } from '../Redux/Actions/envUISidenav';
import { loadAdmin } from '../Redux/Actions/admin';
import Navbar from '../Components/Navbar';
import Sidenav from '../Components/Sidenav';
import LoadingBar from '../Components/LoadingBar';
import Dashboard from './Dashboard/Dashboard';
import InformationManagementHome from './InformationManagement/Home';
import InformationManagement from './InformationManagement';
import UserSearch from './UserSearch/UserSearch';
import UserDetail from './UserManagement/UserDetail';
import UserDetailShort from './UserManagement/UserDetailShort';
import UserEdit from './UserManagement/UserEdit';
import UserEditShort from './UserManagement/UserEditShort';
import ImageProfileManagement from './ImageProfileManagement';
import CampaignManagement from './CampaignManagement';
import JobListManagement from './JobManagement/JobList';
import JobRegisterManagement from './JobManagement/JobRegister';
import OpeJobRegisterManagement from './JobManagement/JobRegisterRoot';
import JobDetailManagement from './JobManagement/JobDetail';
import JobEditManagement from './JobManagement/JobEdit';
import JobEntryMemberListManagement from './JobEntryManagement/JobEntryList';
import PaymentManagement from './PaymentManagement';
import PaymentJobManagement from './PaymentJobManagement';
import UserRegister from './UserManagement/UserRegister';
import ContactManagement from './ContactManagement/ContactManagement';
import Report from './Report';
import PointSetting from './PointSetting/PointSetting';
import Database from './Database';
import ListRawUsers from './Database/ListRawUsers';
import ListContacts from './Database/ListContacts';
import ListMessage from './Database/ListMessage';
import ListCampaign from './Database/ListCampaign';
import ListChat from './Database/ListChat';
import ListPayment from './Database/ListPayment';
import ListRawInformation from './Database/ListRawInformation';
import ListRawTwilioHistory from './Database/ListRawTwilioHistory';
import ListRawTextWebPage from './Database/ListRawTextWebPage';
import ListRawOperator from './Database/ListRawOperator';
import ListRawAgent from './Database/ListRawAgent';
import ListRawMemberArchive from './Database/ListRawMemberArchive';
import ListRawJobDetail from './Database/ListRawJobDetail';
import ListRawMemberAdmin from './Database/ListRawMemberAdmin';
import ListRawOtp from './Database/ListRawOtp';
import ListRawPaymentLogJob from './Database/ListRawPaymentLogJob';
import ListRawPopupSeting from './Database/ListRawPopupSetting';
import OperatorManagement from './OperatorManagement/Operator';
import OperatorDetail from './OperatorManagement/Operator/OperatorDetail';
import OperatorRegister from './OperatorManagement/Operator/OperatorRegist';
import OperatorEdit from './OperatorManagement/Operator/OperatorEdit';
import OpeCharaPageDetail from './OperatorManagement/OpeCharaPage/OpeCharaPageDetail';
import OpeCharaPageEdit from './OperatorManagement/OpeCharaPage/OpeCharaPageEdit';
import LikeManagement from './LikeManagement';
import LogSendManagement from './LogSendManagement';
import SendingTimerManagement from './SendingTimerManagement';
import JobListAgent from './AgentManagement/JobList';
import PointUsage from './PointUsage';
import Enrollment from './Enrollment/Enrollment';
import PopupManagement from './PopupManagement/PopupManagement';
import OperatorSalaryPoint from './OperatorSalaryPoint';
import NameProfile from './NameProfile';
import NameProfileAgent from './NameProfileAgent';
import NameItemJob from './NameItemJob';
import FileUpload from './FileUpload';
import RankSetting from './RankSetting';
import EnvAspSetting from './EnvAspSetting';
import EnvUiWebSetting from './EnvUiWebSetting';
import TwilioManagement from './TwilioManagement/index';
import AgentRegister from './AgentManagement/AgentRegister';
import AgentList from './AgentManagement/AgentList';
import AgentDetail from './AgentManagement/AgentDetail';
import EditAgent from './AgentManagement/AgentEdit';
import AgentCharaList from './AgentManagement/AgentCharaList';
import AgentPageRoot from './AgentManagementAgentPage/AgentPageRoot';
import AgentPageDetail from './AgentManagementAgentPage/AgentPageDetail';
import AgentPageEdit from './AgentManagementAgentPage/AgentPageEdit';
import SalaryLogManagement from './SalaryLogManagement';
import EnvSysCmsSetting from './EnvSysCmsSetting';
import EnvTopNaviWebSetting from './EnvTopNaviWebSetting';
import UserEditMulti from './UserEditMulti';
import FavoriteLogManagement from './FavoriteManagement';
import FavoriteJobManagement from './FavoriteJobManagement';
import FootprintManagement from './FootprintManagement';
import PointGetLogManagement from './PointGetLogManagement';
import OpeSearchUser from './OpeSearchUser/OpeSearchUser';
import OpeWorkPageIndividual from './OpeWorkPageIndividual';
import BlogsManagement from './BlogsManagement';
import BlockManagement from './BlockManagement';
import socket from '../socket';
import cookie from 'js-cookie';
import PointRate from './PointRateManagement';
import NameState from './NameState';
import NameStateAgent from './NameStateAgent';
import NameStateJob from './NameStateJob';
import MemberAdminList from './MemberAdmin/MemberAdminList';
import MemberAdminDetail from './MemberAdmin/MemberAdminDetail';
import MemberAdminEdit from './MemberAdmin/MemberAdminEdit';
import MemberAdmin from './MemberAdmin';
import HelpCmsList from './HelpCms/HelpCmsList';
import MailSmsTextList from './MailSmsText/MailSmsTextList';
import ChatRoomList from './ChatRoom/ChatRoomList';
import EnvValueSetting from './EnvValueSetting';
import ConversionTag from './ConversionTag';
import EnvInfo from './EnvInfo';
import UserArchiveSearch from './UserArchive/UserArchiveSearch';
import OpeCharaSalaryPoint from './OpeCharaSalaryPoint';
import ChangePhoneHistory from './ChangePhoneHistory/LogChangePhone';
import OtpLog from './OtpLog';
import ChatLogUser from './ChatLogUser/ChatLogUser';
import ChatSupportLog from './ChatSupportLog';
import ChatAdminLog from './ChatAdminLog';
import ChatAgentLog from './ChatAgentLog';
import ChatSupportIndividual from './ChatSupportIndividual';
import ChatAdminIndividual from './ChatAdminIndividual';
import AgentWorkPageIndividual from './AgentWorkPageIndividual';
import TagSetting from './TagSetting';
import TagList from './TagList';
import socketMaintenancePage from '../Util/socketMaintenancePage';
import TextWebPageManagement from './TextWebPageManagement';
import NotFound from './404/index';
import ListRawLogMailVerification from './Database/ListRawLogMailVerification';


function Main() {
  const history = useHistory();
  const dispatch = useDispatch();
  const envUICms = useSelector((state) => state.envUICms.data);
  const user = useSelector((state) => state.admin.data);

  useEffect(() => {
    dispatch(loadAdmin());
    dispatch(loadResource({}));
    dispatch(loadNameRank({}));
    dispatch(loadState({}));
    dispatch(loadEnvUICms({ pageIndex: 1, pageSize: 10000 }));
    dispatch(loadEnvUISidenav({ pageIndex: 1, pageSize: 10000 }));
  }, [dispatch]);

  useEffect(() => {
    let token = cookie.get('token');
    socket.emit(
      'authenticate',
      {
        token: token,
      },
      [token],
    );

    // Maintenance with cache
    socketMaintenancePage();
  }, []);

  const checkRouter = () => {
    const userId = localStorage.getItem('userId');
    const operatorId = localStorage.getItem('operatorId');
    const agentId = localStorage.getItem('agentId');
    const keepSidenav = localStorage.getItem('keepSidenav');
    const pathName = history.location.pathname;

    if (keepSidenav
      && !window.location.pathname.match(`/character/(.*)/detailshort`)
      && !window.location.pathname.match(`/character/(.*)/editshort`)
    ) {
      const pathNameSplit = pathName.split('/');

      if (pathName.match(`/user/(.*)/editshort`)
        || pathName.match(`/user/(.*)/detailshort`)
      ) {
        return true;
      }
      if (pathNameSplit[pathNameSplit.length - 1] === 'detail' && pathName.match(/\/job\/([0-9a-z_.-]+)\/detail/)
      || pathNameSplit[pathNameSplit.length - 1] === 'edit' && pathName.match(/\/job\/([0-9a-z_.-]+)\/edit/)
      || pathNameSplit[pathNameSplit.length - 1] === 'detail' && pathName.match(/\/member-job\/([0-9a-z_.-]+)\/detail/)
      || pathNameSplit[pathNameSplit.length - 1] === 'detailope' && pathNameSplit[1] === 'agentope'
      || pathNameSplit[pathNameSplit.length - 1] === 'editagent' && pathNameSplit[1] === 'agentope'
      || pathNameSplit[pathNameSplit.length - 1] === 'edit' && pathName.match(/\/member-job\/([0-9a-z_.-]+)\/edit/)
      ) {
        return true;
      }

      return false;
    }

    if (pathName === `/user/${userId}/detail`
      || pathName.match(`/user/(.*)/detail`)
      || pathName.match('/user/(.*)/edit')
      || pathName.match(`/user/(.*)/editshort`)
      || pathName.match(`/character/(.*)/editshort`)
      || pathName.match(`/character/(.*)/detailshort`)
      || pathName.match(`/user/(.*)/detailshort`)
      || pathName.match(`/user/(.*)/detailuser`)
      || pathName.match(`/agentope/(.*)/detailagent`)
      || pathName.match(`/agentope/(.*)/editagent`)
      || pathName === `/user/detail`
      || pathName === `/user/regist_user`
      || pathName === `/operator/${operatorId}/detail`
      || pathName === `/operator/${operatorId}/edit`
      || pathName === `/operator/${operatorId}/listchara`
      || pathName.match(`/agent/(.*)/detailagent`)
      || pathName === `/agent/${agentId}/editagent`
      || pathName === `/agent/${agentId}/listchara`
      || pathName === `/admin_ope/${operatorId}/detail`
      || pathName === `/admin_ope/search_user/${userId}`
      || pathName === `/operator/salarypoint/${operatorId}`
      || pathName === `/agentope/${agentId}/detailope`
      || pathName === `/agentope/point_usage_log/${agentId}`
      || pathName === `/admin_agent/work_page_individual/${agentId}`
      || pathName === `/admin_ope/work_page_individual/${operatorId}`
      || pathName === `/agentope/job-list/${agentId}`
      || pathName.match('/administrator/(.*)/detail')
      || pathName.match('/administrator/(.*)/edit')
      || pathName === '/operator/detail/search_user'
      || pathName.match(`/job/(.*)/detail`)
      || pathName.match(`/job/(.*)/edit`)
      || pathName.match(`/member-job/(.*)/detail`)
      || pathName.match(`/member-job/(.*)/edit`)
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (!envUICms.length) return <LoadingBar show={!envUICms.length} />;

  return (
    <div>
      {checkRouter() ? null : <Sidenav user={user} />}

      <main className={checkRouter() ? 'disableNarbar' : 'main-page'}>
        <Navbar admin={user} />
        <div className="main-content">
          <Switch>
            <Route exact path="/home" component={InformationManagementHome} />
            <Route exact path="/dashboard" component={Dashboard} />

            <Route exact path="/database/members" component={ListRawUsers} />
            <Route exact path="/database/contacts" component={ListContacts} />
            <Route exact path="/database/message" component={ListMessage} />
            <Route exact path="/database/campaign" component={ListCampaign} />
            <Route exact path="/database/chat" component={ListChat} />
            <Route exact path="/database/payment" component={ListPayment} />
            <Route exact path="/database/information" component={ListRawInformation} />
            <Route exact path="/database/twilio-history" component={ListRawTwilioHistory} />
            <Route exact path="/database/text-web-page" component={ListRawTextWebPage} />
            <Route exact path="/database/operator" component={ListRawOperator} />
            <Route exact path="/database/agent" component={ListRawAgent} />
            <Route exact path="/database/member-archive" component={ListRawMemberArchive} />
            <Route exact path="/database/job-detail" component={ListRawJobDetail} />
            <Route exact path="/database/member-admin" component={ListRawMemberAdmin} />
            <Route exact path="/database/otp" component={ListRawOtp} />
            <Route exact path="/database/payment-log-job" component={ListRawPaymentLogJob} />
            <Route exact path="/database/popup-setting" component={ListRawPopupSeting} />
            <Route exact path="/database/log_mail_verification" component={ListRawLogMailVerification} />
            <Route exact path="/database" component={Database} />

            <Route exact path="/user/edit/multi" component={UserEditMulti} />
            <Route exact path="/user/search" component={UserSearch} />
            <Route exact path="/user/:userId/detail" component={UserDetail} />
            <Route exact path="/user/:userId/edit" component={UserEdit} />
            <Route exact path="/user/:userId/detailshort" component={UserDetailShort} />
            <Route exact path="/user/regist_user" component={UserRegister} />
            <Route exact path="/user_archive/user_archive_list" component={UserArchiveSearch} />
            <Route exact path="/user/:userId/editshort" component={UserEditShort} />
            <Route exact path="/character/:userId/editshort" component={UserEditShort} />

            <Route exact path="/agent/regist_agent" component={AgentRegister} />
            <Route exact path="/agent/:agentId/detailagent" component={AgentDetail} />
            <Route exact path="/agent/:agentId/editagent" component={EditAgent} />
            <Route exact path="/agent/:agentId/listchara" component={AgentCharaList} />
            <Route exact path="/agent/agent_list" component={AgentList} />

            <Route exact path="/management/image_prof" component={ImageProfileManagement} />
            <Route exact path="/management/blog_log" component={BlogsManagement} />
            <Route exact path="/management/log-send" component={LogSendManagement} />
            <Route exact path="/management/like_log" component={LikeManagement} />
            <Route exact path="/management/block_log" component={BlockManagement} />
            <Route exact path="/management/favorite_log" component={FavoriteLogManagement} />
            <Route exact path="/management/favorite_job_log" component={FavoriteJobManagement} />
            <Route exact path="/management/footprint_log" component={FootprintManagement} />
            <Route exact path="/management/timer_standby" component={SendingTimerManagement} />
            <Route exact path="/management/chat_log_user" component={ChatLogUser} />
            <Route exact path="/management/tag_list" component={TagList} />
            <Route exact path="/management/chat_room" component={ChatRoomList} />

            <Route exact path="/admin_ope/search_user/:operatorId" component={OpeSearchUser} />
            <Route exact path="/admin_ope/work_page_individual/:operatorId" component={OpeWorkPageIndividual} />

            <Route exact path="/operator/search_ope" component={OperatorManagement} />
            <Route exact path="/operator/detail/search_user" component={OpeSearchUser} />
            <Route exact path="/operator/salary_log" component={SalaryLogManagement} />
            <Route exact path="/operator/:operatorId/detail" component={OperatorDetail} />
            <Route exact path="/operator/regist_ope" component={OperatorRegister} />
            <Route exact path="/operator/salarypoint/:operatorId" component={OperatorSalaryPoint} />
            <Route exact path="/operator/salary_summary" component={OperatorSalaryPoint} />
            <Route exact path="/operator/:operatorId/edit" component={OperatorEdit} />


            <Route exact path="/agentope/job-list/:agentId" component={JobListAgent} />
            <Route exact path="/agentope/point_usage_log/:agentId" component={PointUsage} />
            <Route exact path="/agentope/:agentId/detailagent" component={AgentPageRoot} />
            <Route exact path="/agentope/:agentId/detailope" component={AgentPageDetail} />
            <Route exact path="/agentope/:agentId/editagent" component={AgentPageEdit} />

            <Route exact path="/contents/popup_setting" component={PopupManagement} />
            <Route exact path="/contents/campaign" component={CampaignManagement} />

            <Route exact path="/job/job-list" component={JobListManagement} />
            <Route exact path="/job/job-register" component={JobRegisterManagement} />
            <Route exact path="/ope-job/job-register" component={OpeJobRegisterManagement} />
            <Route exact path="/job/:jobId/detail" component={JobDetailManagement} />
            <Route exact path="/job/:jobId/edit" component={JobEditManagement} />
            <Route exact path="/job/job-entry" component={JobEntryMemberListManagement} />

            <Route exact path="/log/point_usage" component={PointUsage} />
            <Route exact path="/log/twilio_history" component={TwilioManagement} />
            <Route exact path="/log/enrollment" component={Enrollment} />
            <Route exact path="/log/payment_match" component={PaymentManagement} />
            <Route exact path="/log/payment_job" component={PaymentJobManagement} />
            <Route exact path="/log/get_point" component={PointGetLogManagement} />
            <Route exact path="/log/change_phone" component={ChangePhoneHistory} />
            <Route exact path="/log/otp_log" component={OtpLog} />

            <Route exact path="/point_setting" component={PointSetting} />
            <Route exact path="/point_rate" component={PointRate} />

            <Route exact path="/rank_setting" component={RankSetting} />
            <Route exact path="/top_nav_setting" component={EnvTopNaviWebSetting} />
            <Route exact path="/profile_setting_user" component={NameProfile} />
            <Route exact path="/profile_setting_agent" component={NameProfileAgent} />
            <Route exact path="/item_job_setting" component={NameItemJob} />
            <Route exact path="/tag_name_setting" component={TagSetting} />
            <Route exact path="/text_page_setting" component={TextWebPageManagement} />
            <Route exact path="/file_upload" component={FileUpload} />

            <Route exact path="/profile_state_user" component={NameState} />
            <Route exact path="/profile_state_agent" component={NameStateAgent} />
            <Route exact path="/profile_state_job" component={NameStateJob} />

            <Route exact path="/administrator_list" component={MemberAdminList} />
            <Route exact path="/regist_administrator" component={MemberAdmin} />
            <Route exact path="/administrator/:memberAdminId/detail" component={MemberAdminDetail} />
            <Route exact path="/administrator/:memberAdminId/edit" component={MemberAdminEdit} />

            <Route exact path="/env_mailsms_text" component={MailSmsTextList} />
            <Route exact path="/env_ui_web_setting" component={EnvUiWebSetting} />
            <Route exact path="/env_sys_setting" component={EnvSysCmsSetting} />
            <Route exact path="/env_value_setting" component={EnvValueSetting} />
            <Route exact path="/env_asp_setting" component={EnvAspSetting} />
            <Route exact path="/env_info" component={EnvInfo} />

            <Route exact path="/character/:userId/detailshort" component={UserDetailShort} />
            <Route exact path="/conversion_tag" component={ConversionTag} />

            <Route exact path="/information_setting" component={InformationManagement} />
            <Route exact path="/information_home" component={InformationManagementHome} />

            <Route exact path="/opechara/:operatorId/detail" component={OpeCharaPageDetail} />
            <Route exact path="/opechara/:operatorId/edit" component={OpeCharaPageEdit} />
            <Route exact path="/opechara/salarypoint/:operatorId" component={OpeCharaSalaryPoint} />

            <Route exact path="/chat_support_log" component={ChatSupportLog} />
            <Route exact path="/chat_admin_log" component={ChatAdminLog} />
            <Route exact path="/agent_chat_log" component={ChatAgentLog} />
            <Route exact path="/chat_support_individual" component={ChatSupportIndividual} />
            <Route exact path="/chat_admin_individual" component={ChatAdminIndividual} />
            <Route exact path="/admin_agent/work_page_individual/:id" component={AgentWorkPageIndividual} />
            <Route exact path="/help_cms" component={HelpCmsList} />
            <Route exact path="/contacts" component={ContactManagement} />
            <Route exact path="/reports" component={Report} />

            <Route exact path="/404" component={NotFound} />

            {/* <Route path='*' exact component={NotFound} /> */}
          </Switch>
        </div>
      </main>
    </div>
  );
}

export default Main;
