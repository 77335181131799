import { sendGet, sendPost, sendPostFile } from './axios';

export const getListRawUsers = (params) => sendGet('/cms/database/user', params);

export const getListContacts = (params) => sendGet('/cms/database/contact', params);

export const getListMessage = (params) => sendGet('/cms/database/message', params);

export const getListCampaign = (params) => sendGet('/cms/database/campaign', params);

export const getListChat = (params) => sendGet('/cms/database/chat', params);

export const getListPayment = (params) => sendGet('/cms/database/payment', params);

export const exportData = (params) => sendPost('/cms/database/export', params);

export const importData = (params) => sendPostFile('/cms/database/import', params);
export const importDataTest = (params) => sendPostFile('/cms/database/import-test', params);

export const exportAllData = (params) => sendPost('/cms/database/export/all', params);

export const importAllData = (params) => sendPostFile('/cms/database/import/all', params);

export const deleteData = (params) => sendPost('/cms/database/delete', params);

export const clearData = (params) => sendPost('/cms/database/clear-data', params);

export const clearFolderImage = (params) => sendPost('/cms/database/clear-folder-image', params);

export const deleteTableLog = (params) => sendPost('/cms/database/delete-log', params);

export const deleteTableAll = (params) => sendPost('/cms/database/delete/all', params);

// information
export const getListInformation = (params) => sendGet('/cms/database/information', params);
// twilio-history
export const getListTwilioHistory = (params) => sendGet('/cms/database/twilio-history', params);
// text-web-page
export const getListTextWebPage = (params) => sendGet('/cms/database/text-web-page', params);
// operator
export const getListOperator = (params) => sendGet('/cms/database/operator', params);
// agent
export const getListAgent = (params) => sendGet('/cms/database/agent', params);
// member_archive
export const getListMemberArchive = (params) => sendGet('/cms/database/member-archive', params);
// member_admin
export const getListMemberAdmin = (params) => sendGet('/cms/database/member-admin', params);
// otp
export const getListOtp = (params) => sendGet('/cms/database/otp', params);
// payment log
export const getListPaymentLogJob = (params) => sendGet('/cms/database/payment-log-job', params);
// popup setting
export const getListPopupSetting = (params) => sendGet('/cms/database/popup-setting', params);
// log mail verification
export const getListLogMailVerification = (params) => sendGet('/cms/database/log-mail-verification', params);
// job_detail
export const getListJobDetail = (params) => sendGet('/cms/database/job-detail', params);